/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';
import { DateTime } from 'luxon';
import get from 'lodash.get';

import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Table,
  Pagination,
  Form,
  ButtonGroup,
} from 'react-bootstrap';

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { useQuery, NetworkStatus } from '@apollo/client';
import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';
import ReactTableFilter from '../components/react_table_filter';
import pageMxtoolboxDetailListQuery from '../queries/page_mxtoolbox_detail_list_query';

const MxtoolboxDetailList = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [isAuthorized] = useState(true);
  const [mxtoolboxDetailList, setMxtoolboxDetailList] = useState([]);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false }]);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageMxtoolboxDetailListQuery, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (pageData?.mxtoolboxDetailList) {
      setMxtoolboxDetailList(pageData.mxtoolboxDetailList);
    }
  }, [pageData]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleShow = useCallback(
    (e, path) => {
      const id = e.currentTarget.getAttribute('data-id');
      navigate(`/search/${path}/${id}`);
    },
    [navigate]
  );

  const filterWithUndefined = (row, column, filter) => {
    if (filter.length < 2) {
      return false;
    }
    const value = get(row, ['original', ...column.split('_')]);
    return !!(value && value.toLowerCase().includes(filter.toLowerCase()));
  };

  const formatDateCell = useCallback((cell) => {
    const { createdAtDateTimeUtc } = cell.getValue();
    return DateTime.fromISO(createdAtDateTimeUtc)
      .setLocale('en-nz')
      .toLocaleString(DateTime.DATETIME_SHORT);
  }, []);

  const renderActionCell = useCallback(
    (cell) => {
      const id = cell.getValue();
      return (
        <ButtonGroup key={id}>
          <Button
            className="me-1"
            size="sm"
            data-id={id}
            onClick={(e) => handleShow(e, 'mxtoolbox-detail')}
          >
            show mxtoolbox detail
          </Button>

          <Button
            className="me-1"
            size="sm"
            data-id={id}
            onClick={(e) => handleShow(e, 'drat-scorecard')}
          >
            show drat scorecard
          </Button>
        </ButtonGroup>
      );
    },
    [handleShow]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Domain Name',
        id: 'id',
        accessorKey: 'id',
        filterFn: filterWithUndefined,
      },
      createColumnHelper().accessor('detail', {
        id: 'createdAt',
        header: () => 'Created At',
        cell: formatDateCell,
      }),
      createColumnHelper().accessor('detail.id', {
        id: 'detail.id',
        header: () => 'Actions',
        cell: renderActionCell,
      }),
    ],
    [formatDateCell, renderActionCell]
  );

  const reactTable = useReactTable({
    columns,
    data: mxtoolboxDetailList,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize: 100 } },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  const renderContent = () => {
    return (
      <>
        <Helmet title="Mxtoolbox Detail List" />
        <h1>Mxtoolbox Detail List</h1>
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                <div className="float-end my-2">
                  <Button
                    variant="primary"
                    onClick={handleRefetch}
                    disabled={pageLoading}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {mxtoolboxDetailList.length ? (
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Table size="sm" responsive striped bordered>
                      <thead>
                        {reactTable.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? 'cursor-pointer select-none'
                                          : '',
                                        onClick: header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: ' 🔼',
                                        desc: ' 🔽',
                                      }[header.column.getIsSorted()] ?? null}
                                    </div>
                                    {header.column.getCanFilter() && (
                                      <div>
                                        <ReactTableFilter
                                          column={header.column}
                                          reactTable={reactTable}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {reactTable.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col md="6">
                        <span className="mx-2">
                          Page{' '}
                          <strong>
                            {reactTable.getState().pagination.pageIndex + 1} of{' '}
                            {reactTable.getPageCount()}
                          </strong>
                        </span>
                        <span className="ms-3 me-2">Show:</span>
                        <Form.Select
                          className="d-inline-block w-auto"
                          value={reactTable.getState().pagination.pageSize}
                          onChange={(e) => {
                            reactTable.setPageSize(Number(e.target.value));
                          }}
                        >
                          {[10, 25, 50, 100].map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </Form.Select>

                        <span className="ms-3 me-2">Go to page:</span>
                        <Form.Control
                          className="d-inline-block"
                          type="number"
                          defaultValue={reactTable.getState().pagination.pageIndex + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            reactTable.setPageIndex(pageNumber);
                          }}
                          style={{ width: '75px' }}
                        />
                      </Col>
                      <Col md="6">
                        <Pagination className="float-end">
                          <Pagination.First
                            onClick={() => reactTable.setPageIndex(0)}
                            disabled={!reactTable.getCanPreviousPage()}
                          />
                          <Pagination.Prev
                            onClick={() => reactTable.previousPage()}
                            disabled={!reactTable.getCanPreviousPage()}
                          />
                          <Pagination.Next
                            onClick={() => reactTable.nextPage()}
                            disabled={!reactTable.getCanNextPage()}
                          />
                          <Pagination.Last
                            onClick={() =>
                              reactTable.setPageIndex(reactTable.getPageCount() - 1)
                            }
                            disabled={!reactTable.getCanNextPage()}
                          />
                        </Pagination>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the Mxtoolbox Detail List
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  };
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default MxtoolboxDetailList;
