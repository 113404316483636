/* eslint-disable  react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Form,
  Col,
  Container,
  Row,
  ListGroup,
  Button,
  Tab,
  Nav,
  Badge,
} from 'react-bootstrap';
import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { DateTime } from 'luxon';
import get from 'lodash.get';
import pick from 'lodash.pick';
import { Letter } from 'react-letter';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import pageCheckableMessageShowQuery from '../queries/page_checkable_message_query';
import capturedMessageQuery from '../queries/captured_message_query';
import sampledMessageCategoriseMutation from '../mutations/sampled_message_categorise_mutation';
import checkableMessageDeleteMutation from '../mutations/checkable_message_delete_mutation';
import { toastSuccess, toastError } from '../lib/toast';
import useSettings from '../hooks/use_settings';

const CheckableMessage = () => {
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { isMutating, setIsMutating } = useSettings();
  const [queue, setQueue] = useState('low');
  const [checkableMessage, setCheckableMessage] = useState();
  const [capturedMessage, setCapturedMessage] = useState();
  const [sampledMessageCategorise] = useMutation(sampledMessageCategoriseMutation);
  const [checkableMessageDelete] = useMutation(checkableMessageDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageCheckableMessageShowQuery, {
    variables: { queue },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: lazyData,
    loading: lazyLoading,
    error: lazyError,
  } = useQuery(capturedMessageQuery, {
    variables: { id: get(checkableMessage, 'messageDetail.smxProtoMessageId') },
    skip: !get(checkableMessage, 'messageDetail.smxProtoMessageId'),
  });

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  useEffect(() => {
    setCheckableMessage(get(pageData, 'checkableMessage'));
  }, [pageData]);

  useEffect(() => {
    setCapturedMessage(get(lazyData, 'capturedMessage'));
  }, [lazyData]);

  const handleSelectQueue = (e) => {
    setQueue(e.target.value);
  };

  const handleSkip = () => {
    pageRefetch();
  };

  const handleCheckableMessageDelete = async () => {
    const input = pick(checkableMessage, ['messageId', 'popReceipt', 'queue']);
    const mutationData = {
      variables: { input },
    };
    try {
      setIsMutating(true);
      await checkableMessageDelete(mutationData);
      handleSkip();
      toastSuccess('Checkable Message delete ok');
    } catch (err) {
      console.log(err.toString());
      toastError('Checkable Message delete failed');
    } finally {
      setIsMutating(false);
    }
  };

  const handleSampledMessageCategorise = async (supportSmxCategory) => {
    const id = get(checkableMessage, 'messageDetail.smxOriginalMessageId');
    const mutationData = {
      variables: { input: { id, supportSmxCategory } },
    };
    try {
      setIsMutating(true);
      await sampledMessageCategorise(mutationData);
      handleCheckableMessageDelete();
      toastSuccess('Sample Message categorise ok');
    } catch (err) {
      console.log(err.toString());
      toastError('Sample Message categorise failed');
    } finally {
      setIsMutating(false);
    }
  };

  const handleSpam = () => {
    handleSampledMessageCategorise('SPAM');
  };

  const handleNotSpam = () => {
    handleSampledMessageCategorise('NOTSPAM');
  };

  const renderContent = () => {
    const { messageDetail } = checkableMessage || {};
    const { sender, envelopeSender, cat, smxCategory } = messageDetail || {};
    const { email } = capturedMessage || {};
    const { to, from, subject, date, attachments, text, html } = email || {};

    return (
      <>
        <Helmet title="Checkable Message" />
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                <div className="float-start">
                  <h1 className="h3 mb-3">Spam Or Not?</h1>
                </div>
                <div className="float-end">
                  <Form.Select
                    id="queueSelect"
                    name="queueSelect"
                    value={queue}
                    onChange={handleSelectQueue}
                  >
                    <option value="high">High priority queue</option>
                    <option value="low">Low priority queue</option>
                  </Form.Select>
                </div>
                <div className="float-end">
                  <Button
                    className="me-2"
                    variant="success"
                    onClick={handleNotSpam}
                    disabled={!checkableMessage}
                  >
                    Not Spam
                  </Button>
                </div>
                <div className="float-end">
                  <Button
                    className="me-2"
                    variant="primary"
                    onClick={handleSkip}
                    disabled={!checkableMessage}
                  >
                    Skip Message
                  </Button>
                </div>
                <div className="float-end">
                  <Button
                    className="me-2"
                    variant="danger"
                    onClick={handleSpam}
                    disabled={!checkableMessage}
                  >
                    Spam
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tab">
                <Tab.Container id="left-tabs-example" defaultActiveKey="capture">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="capture">Captured Message</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="attachments">Attachments</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="detail">Checkable Message Detail</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="capture">
                      {cat && (
                        <Row>
                          <Col>
                            <div className="float-none">
                              <div className="float-start">
                                <h2>
                                  Microsoft Says <Badge bg="primary">{cat}</Badge>
                                </h2>
                              </div>
                              <div className="float-end">
                                <h2>
                                  SMX Says <Badge bg="primary">{smxCategory}</Badge>
                                </h2>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {to ? (
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              DATE:
                            </span>
                            {DateTime.fromISO(date)
                              // .setLocale('en-nz')
                              .toLocaleString(DateTime.DATETIME_SHORT)}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              SENDER:
                            </span>
                            {envelopeSender || sender}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              FROM:
                            </span>
                            {from}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              TO:
                            </span>
                            {to}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              SUBJECT:
                            </span>
                            {subject}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <Letter {...(html && { html })} {...(text && { text })} />
                          </ListGroup.Item>
                        </ListGroup>
                      ) : (
                        <div>
                          <p>We were unable to retrieve the message for review</p>
                          <ul>
                            <li>It may have expired from capture</li>
                            <li>You might be mucking about in dev or staging</li>
                          </ul>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="attachments">
                      {attachments && attachments.length > 0 ? (
                        <ListGroup variant="flush">
                          {attachments.map(({ filename, contentType }) => (
                            <ListGroup.Item
                              key={filename}
                            >{`${filename} (${contentType})`}</ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <p>No attachments</p>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="detail">
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {checkableMessage ? (
                            <pre>{JSON.stringify(checkableMessage, undefined, 2)}</pre>
                          ) : (
                            <p>No message details</p>
                          )}
                        </ListGroup.Item>
                      </ListGroup>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading || lazyLoading || isMutating)}
      {renderError(pageError || lazyError)}
      {isAuthenticated &&
        !pageError &&
        !lazyError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default CheckableMessage;
