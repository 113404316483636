import { useState } from 'react';
import { Container, Card, Row, Col, Form } from 'react-bootstrap';

const EmailFooter = () => {
  const [data, setData] = useState({
    fullName: '',
    title: '',
    email: '@smxemail.com',
    mobile: '+64 ',
    ddi: '',
    showSalesSupport: true,
    showLinks: true,
    logo: 'https://smxemail.com/media/rr0ps101/smxlogotext.png',
    twitterLogo: 'https://smxemail.com/media/y1iop0lz/email_twitter.png',
    linkedinLogo: 'https://smxemail.com/media/qs1kg2x3/email_linkedin.png',
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSwitchAction = (attr, value) => {
    setData({ ...data, [attr]: !value });
  };

  const output = () => {
    const {
      fullName,
      title,
      mobile,
      ddi,
      showSalesSupport,
      showLinks,
      logo,
      twitterLogo,
      linkedinLogo,
    } = data;

    const smxwebURL =
      'https://smxemail.com/?utm_source=email&utm_medium=signature&utm_campaign=email_signature';
    return (
      <p
        style={{ fontSize: '14pt', fontFamily: 'Segoe UI,sans-serif;line-height:1.5em' }}
      >
        <b>{fullName}</b> | {title}
        <br />
        {mobile?.length > 1 && (
          <>
            <b>Mobile: </b>
            <span> {mobile}</span>
            <br />
          </>
        )}
        {ddi?.length > 1 && (
          <>
            <b>Direct Dial: </b>
            <span> {ddi}</span>
            <br />
          </>
        )}
        {showSalesSupport && (
          <>
            <span>
              <b>Support, Sales & Accounts: </b>
              NZ 0800 769 769 | AU 1800 476 976
            </span>
            <br />
          </>
        )}
        {showSalesSupport && (
          <>
            <span>
              <b>Support, Sales & Accounts International: </b>+ 64 9 302 0515
            </span>
            <br />
          </>
        )}
        <b>SMX Limited: </b>
        B:HIVE, Smales Farm, Auckland, NZ
        <br />
        <b>Web: </b>
        <a href={smxwebURL}>https://smxemail.com/</a>
        <br />
        <a href={smxwebURL}>
          <img alt="logo" style={{ width: '200px', maxWidth: '200px' }} src={logo} />
        </a>
        <br />
        {showLinks && (
          <>
            <br />
            <a href="https://twitter.com/SMXemail/" style={{ marginRight: '8px' }}>
              <img style={{ maxWidth: '35px' }} alt="twitterlogo" src={twitterLogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/smx/"
              style={{ marginRight: '8px' }}
            >
              <img style={{ maxWidth: '35px' }} alt="linkedinlogo" src={linkedinLogo} />
            </a>
          </>
        )}
      </p>
    );
  };

  const outputHTML = () => {
    const {
      fullName,
      title,
      mobile,
      ddi,
      showSalesSupport,
      showLinks,
      logo,
      twitterLogo,
      linkedinLogo,
    } = data;
    const text = [];

    const smxwebURL =
      'https://smxemail.com/?utm_source=email&utm_medium=signature&utm_campaign=email_signature';

    text.push(
      '<p style="font-size:14pt;font-family:Segoe UI,sans-serif;line-height:1.5em">'
    );

    text.push(`<b>${fullName}</b> | ${title}<br>`);
    if (mobile.length > 1) {
      text.push(`<b>Mobile:</b><span> ${mobile}</span><br>`);
    }
    if (ddi.length > 1) {
      text.push(`<b>Direct Dial:</b> <span> ${ddi}</span><br>`);
    }
    if (showSalesSupport === true) {
      text.push(
        '<b><span>Support, Sales & Accounts:</b> NZ 0800 769 769 | AU 1800 476 976</span><br>'
      );
      text.push(
        '<b><span>Support, Sales & Accounts International:</b> +64 9 302 0515</span><br>'
      );
    }
    text.push('<b>SMX Limited:</b> B:HIVE, Smales Farm, Auckland, NZ<br>');
    text.push(`<b>Web:</b> <a href="${smxwebURL}">https://smxemail.com/</a><br>`);
    text.push(
      `<a href="${smxwebURL}"><img style="width: 200px; max-width:200px;" width="200" src="${logo}"></a>`
    );
    if (showLinks === true) {
      text.push(
        `<br><a href="https://twitter.com/SMXemail/"><img style="width: 24px; max-width:24px;" width="24" src="${twitterLogo}"></a> <a href="https://www.linkedin.com/company/smx/"><img style="width: 24px; max-width:24px; width="24" src="${linkedinLogo}">`
      );
    }
    text.push('</p>');
    return text.join('');
  };

  const renderContent = () => {
    const { fullName, title, mobile, ddi, showSalesSupport, showLinks } = data;

    console.log(data);

    return (
      <Container
        fluid
        className="p-2"
        style={{
          width: '80%',
          marginTop: '4%',
          fontSize: '11pt',
          fontFamily: 'Segoe UI,sans-serif;line-height:1.5em',
        }}
      >
        <Row wi>
          <Col sm={12}>
            <section>
              <Card>
                <Card.Body>
                  <Card.Title>Enter Your Details</Card.Title>
                  <Card.Text>
                    <Form.Group className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        size="lg"
                        aria-label="Large"
                        name="fullName"
                        type="text"
                        value={fullName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        size="lg"
                        name="title"
                        type="text"
                        value={title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        SMX Mobile Number (Please remove the +64 if mobile number not
                        applicable)
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        name="mobile"
                        type="text"
                        value={mobile}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>SMX DD Number (Leave blank to hide)</Form.Label>
                      <Form.Control
                        size="lg"
                        name="ddi"
                        type="text"
                        value={ddi}
                        onChange={handleChange}
                      />
                      <Form.Switch
                        checked={showSalesSupport}
                        id="custom-switch"
                        label="Show Support and Sales free phone numbers"
                        onChange={() =>
                          handleSwitchAction('showSalesSupport', showSalesSupport)
                        }
                      />
                      <Form.Switch
                        checked={showLinks}
                        id="custom-switch"
                        label="Show Twitter/Linkedin links"
                        onChange={() => handleSwitchAction('showLinks', showLinks)}
                      />
                    </Form.Group>
                  </Card.Text>
                </Card.Body>
              </Card>
            </section>
            <Col sm={12}>
              <Card>
                <Card.Body>
                  <Card.Title>Cut &amp; Paste directly into Outlook</Card.Title>
                  <Card.Text>
                    <span>{output()}</span>
                    <hr />
                    <b>Instructions: </b> Select from your name all the way to after the
                    images and copy. Open signatures in mail client and paste. If this
                    does not work, you may need to use the source code below.{' '}
                    <i>This page will not save any of your information.</i>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    Cut &amp; Paste the raw HTML if needed (Double click to select all)
                  </Card.Title>
                  <Card.Text>
                    <span>{outputHTML()}</span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  };

  return renderContent();
};

export default EmailFooter;
