import React, { useState, useMemo } from 'react';
import usePersistentState from '../hooks/use_persistent_state';

const initialState = {
  isOpen: true,
  isMutating: false,
};

const SettingsContext = React.createContext(initialState);

function SettingsProvider({ children }) {
  const [isOpen, setIsOpen] = useState(initialState.isOpen);
  const [isMutating, setIsMutating] = useState(initialState.isMutating);
  const contextValue = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      isMutating,
      setIsMutating,
    }),
    [isOpen, setIsOpen, isMutating, setIsMutating]
  );

  return (
    <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
