import { Helmet } from 'react-helmet-async';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';
import pageHelloShowQuery from '../queries/page_hello_show_query';

const Graphql = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useQuery(pageHelloShowQuery);

  const renderContent = () => {
    const { hello } = pageData;
    return (
      <>
        <Helmet title="Graphql" />
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                <div className="float-start">
                  <h1 className="h3 mb-3">Hello Detail</h1>
                </div>
                <div className="float-end">
                  <Button
                    className="me-2"
                    variant="primary"
                    onClick={() => navigate('/')}
                  >
                    Home
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title tag="h5" className="mb-0">
                    Graphql
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>{hello.id}</p>
                  <p>{hello.greeting}</p>
                  <p>{hello.enthusiasm}</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageLoading && !pageError && renderContent()}
    </div>
  );
};

export default Graphql;
