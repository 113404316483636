import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { SettingsProvider } from './contexts/settings_context';
import ErrorBoundaryLayout from './layouts/error_boundary_layout';

import AppRoutes from './lib/routes';

// appstack stylesheet (includes bootstrap)
import './styles/light.css';
// toasts
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const renderErrorBoundary = ({ error, componentStack }) => (
    <ErrorBoundaryLayout error={error} componentStack={componentStack} />
  );

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | SMX Admin Center" defaultTitle="SMX Admin Center" />
      <Sentry.ErrorBoundary fallback={renderErrorBoundary}>
        <ToastContainer />
        <SettingsProvider>
          <AppRoutes />
        </SettingsProvider>
      </Sentry.ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
