import PerfectScrollbar from 'react-perfect-scrollbar';

import useSettings from '../../hooks/use_settings';
import SidebarFooter from './sidebar/sidebar_footer';
import SidebarNav from './sidebar/sidebar_nav';
// import { ReactComponent as Logo } from '../../images/logo.svg';
// import Logo from '../../images/logo.svg';
// console.log(Object.keys(logos));
// const Logo = logos.default;

// console.log(Object.keys(Logo));

import dashboardItems from '../../lib/dashboard_items';

const Sidebar = ({ showFooter = false }) => {
  const { isOpen } = useSettings();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            {/* <Logo /> <span className="align-middle me-3">SMX</span> */}
            Admin Center
          </a>

          <SidebarNav items={dashboardItems} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
