import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Container, Row, Button, ListGroup, Tab, Nav } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import pageMxtoolboxDetailQuery from '../queries/page_mxtoolbox_detail_query';

const MxtoolboxDetailShow = () => {
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();
  const navigate = useNavigate();

  const [isAuthorized] = useState(true);
  const [mxtoolboxDetail, setMxtoolboxDetail] = useState({});
  const [tags, setTags] = useState([]);
  const [mxtoolboxDetailId, setMxtoolboxDetailId] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageMxtoolboxDetailQuery, {
    variables: { id: mxtoolboxDetailId },
    skip: !mxtoolboxDetailId,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setMxtoolboxDetailId(params.id);
    if (!params.id) {
      setMxtoolboxDetail({});
    }
  }, [params.id]);

  useEffect(() => {
    if (pageData?.mxtoolboxDetail) {
      setMxtoolboxDetail(pageData.mxtoolboxDetail);
    }
  }, [pageData]);

  useEffect(() => {
    if (mxtoolboxDetail?.detail?.dmarc?.Information?.length) {
      setTags(mxtoolboxDetail.detail.dmarc.Information);
    }
  }, [mxtoolboxDetail]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleFetch = () => {
    navigate(`/search/mxtoolbox-detail/${inputValue}`);
  };

  const handleRefetch = () => pageRefetch();

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const renderContent = () => {
    return (
      <>
        <Helmet title="Mxtoolbox Detail Show" />
        <h1>Mxtoolbox Detail Show</h1>
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                <div className="float-end my-2">
                  <Button
                    variant="primary"
                    onClick={handleRefetch}
                    disabled={pageLoading}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <div className="input-group mb-3 col-xs-2">
              <input
                type="text"
                className="form-control"
                placeholder="enter a domain"
                aria-label="domain"
                aria-describedby="basic-addon2"
                onChange={handleChange}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleFetch}
                  disabled={!inputValue || pageLoading}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {mxtoolboxDetail?.detail ? (
            <Row>
              <Col>
                <div className="tab">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="detail">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="detail">Detail</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="tags">Tags</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="detail">
                        <ListGroup variant="flush">
                          <ListGroup.Item variant="info">
                            <span style={{ display: 'inline-block', width: '300px' }}>
                              Domain: {mxtoolboxDetail.id}
                            </span>
                          </ListGroup.Item>
                        </ListGroup>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <pre className="h6">
                              {JSON.stringify(mxtoolboxDetail, undefined, 2)}
                            </pre>
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                      <Tab.Pane eventKey="tags">
                        <ListGroup variant="flush">
                          <ListGroup.Item variant="info">
                            <span style={{ display: 'inline-block', width: '300px' }}>
                              Domain: {mxtoolboxDetail.id}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {tags.length ? (
                              <pre className="h6">
                                {JSON.stringify(tags, undefined, 2)}
                              </pre>
                            ) : (
                              <p>No tags provided!</p>
                            )}
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          ) : (
            params.id && (
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="pb-0">
                      <Card.Title tag="h5" className="mb-0">
                        Not found
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      We were unable to retrieve the Mxtoolbox Detail Item for review
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )
          )}
        </Container>
      </>
    );
  };
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default MxtoolboxDetailShow;
