import { useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import mxtoolbxDetailCreateMutation from '../mutations/mxtoolbox_detail_create_mutation';
import { toastSuccess, toastError } from '../lib/toast';
import useSettings from '../hooks/use_settings';

const MxtoolboxDetailCreate = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized] = useState(true);
  const { isMutating, setIsMutating } = useSettings();
  const navigate = useNavigate();

  const [mxtoolboxDetailCreate, { loading: mutationLoading, error: mutationError }] =
    useMutation(mxtoolbxDetailCreateMutation);

  const [inputDomain, setInputDomain] = useState(null);

  const handleMxtoolboxDetailCreate = async (domain) => {
    if (domain) {
      const mutationOptions = {
        variables: { input: { domain } },
      };
      try {
        setIsMutating(true);
        await mxtoolboxDetailCreate(mutationOptions);
        toastSuccess('MX Toolbox Detail Creation ok');
        navigate(`/search/mxtoolbox-detail/${domain}`);
      } catch (err) {
        console.log(err.toString());
        toastError('MX Toolbox Detail Creation failed');
      } finally {
        setIsMutating(false);
      }
    }
  };

  const handleChange = (e) => {
    setInputDomain(e.target.value);
  };

  const renderContent = () => (
    <div className="row">
      <p className="h2 fw-normal mt-3 mb-4">MX Toolbox Detail</p>
      <div>
        <div className="input-group mb-3 col-xs-2">
          <input
            type="text"
            className="form-control"
            placeholder="enter a domain"
            aria-label="domain"
            aria-describedby="basic-addon2"
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleMxtoolboxDetailCreate(inputDomain)}
              disabled={!inputDomain || mutationLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(isMutating)}
      {renderError(mutationError)}
      {isAuthenticated && isAuthorized && !mutationError && renderContent()}
    </div>
  );
};

export default MxtoolboxDetailCreate;
