import { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { renderOverlay, renderError } from '../components/render_helpers';
import pageMessageQuery from '../queries/page_message_query';

const Message = () => {
  const [inputValue, setInputValue] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [message, setMessage] = useState({});

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useQuery(pageMessageQuery, {
    variables: { id: messageId },
    skip: !messageId,
  });

  useEffect(() => {
    setMessage(pageData?.message?.message);
  }, [pageData]);

  const handleFetch = useCallback(() => {
    console.log('handle fetch');
    console.log(inputValue);
    setMessageId(inputValue);
  }, [inputValue]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const renderContent = () => (
    <div className="row">
      <p className="h2 fw-normal mt-3 mb-4">Message search by sid</p>
      <div>
        <div className="input-group mb-3 col-xs-2">
          <input
            type="text"
            className="form-control"
            placeholder="enter a sid"
            aria-label="sid"
            aria-describedby="basic-addon2"
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={handleFetch}>
              Search
            </button>
          </div>
        </div>
      </div>
      {message && <pre className="h6">{JSON.stringify(message, undefined, 2)}</pre>}
    </div>
  );

  return (
    <div>
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {!pageError && renderContent()}
    </div>
  );
};
// 623D0403-5DBA36EA@mtaau403
export default Message;
