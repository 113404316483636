import { Outlet } from 'react-router-dom';

import Wrapper from './components/wrapper';
import Sidebar from './components/sidebar';
import Main from './components/main';
import Navbar from './components/navbar';
import Content from './components/content';
import Footer from './components/footer';

const DashboardLayout = ({ children }) => (
  <Wrapper>
    <Sidebar />
    <Main>
      <Navbar />
      <Content>
        {children}
        <Outlet />
      </Content>
      <Footer />
    </Main>
  </Wrapper>
);

export default DashboardLayout;
