import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';

const Home = () => (
  <>
    <Helmet title="Home" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">SMX Admin Center</h1>
    </Container>
  </>
);

export default Home;
