import { Layout, Code } from 'react-feather';

const searchSection = [
  // {
  //   href: '/search',
  //   icon: Layout,
  //   title: 'Search',
  //   children: [
  //     {
  //       href: '/search/services',
  //       title: 'Service Items',
  //     },
  //   ],
  // },
  {
    href: '/search',
    icon: Layout,
    title: 'Search',
    children: [
      {
        href: '/search/message',
        title: 'Logstream Messages',
      },
      {
        href: '/search/checkable-message',
        title: 'Checkable Messages',
      },
      {
        href: '/search/drat-scorecard',
        title: 'Drat Scorecards',
      },
      {
        href: '/search/mxtoolbox-detail',
        title: 'Mxtoolbox Details',
      },
      {
        href: '/search/drat-request',
        title: 'Drat Request',
      },
    ],
  },
  {
    href: '/develop',
    icon: Code,
    title: 'Develop',
    children: [
      {
        href: '/develop/profile',
        title: 'Profile',
      },
      {
        href: '/develop/function',
        title: 'Function',
      },
      {
        href: '/develop/graphql',
        title: 'Graphql',
      },
    ],
  },

  {
    href: '/tenants',
    icon: Code,
    title: 'Tenant',
    children: [
      {
        href: '/tenants',
        title: 'Tenant List',
      },
    ],
  },
  {
    href: '/mxtoolbox-details',
    icon: Code,
    title: 'MX Toolbox',
    children: [
      {
        href: '/mxtoolbox-details/new',
        title: 'Create New MX Toolbox Detail',
      },
      {
        href: '/mxtoolbox-details',
        title: 'MX Toolbox Details List',
      },
    ],
  },
  {
    href: '/drat-requests',
    icon: Code,
    title: 'Drat Request',
    children: [
      {
        href: '/drat-requests',
        title: 'Drat Requests List',
      },
      {
        href: '/drat-requests/new',
        title: 'Create New Drat Request',
      },
    ],
  },
  {
    href: '/emailfooter',
    icon: Code,
    title: 'Email Footer',
  },
];

const navItems = [
  {
    title: 'Search',
    showTitle: false,
    pages: searchSection,
  },
];

export default navItems;
