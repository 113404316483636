import React, { useEffect, useCallback } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { loginRequest } from '../lib/auth_config';
import screenshotMixed from '../images/screenshot_mixed.jpg';
import Logo from '../images/smx-color-horiz.svg';

const Intro = ({ handleLogin }) => (
  <section className="landing-intro landing-bg pt-4 pt-lg-5 pb-4 pb-lg-5">
    <Container className="landing-intro-content">
      <Row className="align-items-center">
        <Col lg="5" className="mx-auto">
          <Logo style={{ width: '60%', height: '60%' }} />
          <h1 className="my-4">SMX Admin Center</h1>
          <div className="my-4">
            <Button variant="success" className="me-2" size="lg" onClick={handleLogin}>
              Login
            </Button>
          </div>
        </Col>
        <Col lg="7" className="d-none d-lg-flex mx-auto text-center">
          <div className="landing-intro-screenshot pb-3">
            <img
              src={screenshotMixed}
              alt="Dark/Light Bootstrap React Admin Template"
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

const Footer = () => (
  <section className="landing-footer py-4">
    <Container className="text-center landing-footer-container">
      <Row>
        <Col md="9" lg="8" xl="6" className="mx-auto">
          <h4 className="h4">Tools for champions</h4>
        </Col>
      </Row>
    </Container>
  </section>
);

const Landing = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = useCallback(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      try {
        await instance.handleRedirectPromise();
        await instance.loginRedirect(loginRequest);
      } catch (err) {
        console.log('Login Error');
        console.log(err);
      }
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <>
      <Intro handleLogin={handleLogin} />
      <Footer />
    </>
  );
};

export default Landing;
