import { Dropdown } from 'react-bootstrap';
import { Settings, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';

// import avatar1 from "../../assets/img/avatars/avatar.jpg";

const NavbarUser = ({ handleLogout, account }) => {
  const navigate = useNavigate();
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {account && account.name && <span className="text-dark">{account.name}</span>}
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate('/develop/profile')}>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
