import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';

import { Card, Col, Container, Row, Button, ListGroup, Tab } from 'react-bootstrap';

import { useQuery, NetworkStatus } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import pageTenantUserQuery from '../queries/page_tenant_user_show_query';

const TenantUserShow = () => {
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();

  const [isAuthorized] = useState(true);
  const [tenantUser, setTenantUser] = useState(null);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageTenantUserQuery, {
    variables: { id: params.id, userId: params.userId },
    skip: !params.id,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (pageData?.tenantUser) {
      setTenantUser(pageData.tenantUser);
    }
  }, [pageData]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const renderContent = () => {
    const { id, displayName } = tenantUser;
    return (
      <>
        <p className="h2 fw-normal mt-3 mb-4">Tenant User</p>
        <Helmet title="Tenant User" />
        <Container fluid className="p-0">
          {id ? (
            <>
              <Row>
                <Col sm={12}>
                  <div className="float-none">
                    {/* <div className="float-start">
                    <h1 className="h3 mb-3">{`Tenant list for ${get(account,'name','user')}`}</h1>
                  </div> */}
                    <div className="float-end my-2">
                      <Button
                        variant="primary"
                        onClick={handleRefetch}
                        disabled={pageLoading}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
                      <Tab.Content>
                        <Tab.Pane eventKey="basic">
                          <ListGroup variant="flush">
                            <ListGroup.Item variant="info">
                              <span style={{ display: 'inline-block', width: '150px' }}>
                                Tenant user info:
                              </span>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                ID:
                              </span>
                              {id}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                Name:
                              </span>
                              {displayName}
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the tenant user for review
                    <ul>
                      <li>It may have expired</li>
                      <li>This id may not relate to your id</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  };

  // return (
  // <>
  //   <Helmet title="Tenant User Show" />
  //   <Container fluid className="p-0">
  //     <h1 className="h3 mb-3">TenantUserShow page</h1>
  //   </Container>
  // </>
  // )

  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default TenantUserShow;
