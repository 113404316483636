import { Helmet } from 'react-helmet-async';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { protectedResources } from '../lib/auth_config';
import getAccessToken from '../lib/get_access_token';
import { DisplayProfileData } from '../components/display_data';

const Profile = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      try {
        const accessToken = await getAccessToken(protectedResources.GraphMe);
        if (accessToken) {
          const graphResponse = await axios({
            method: 'get',
            url: protectedResources.GraphMe.endpoint,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setData(graphResponse.data);
        }
      } catch (err) {
        console.log(err.toString());
      }
    }
    if (isAuthenticated && inProgress === 'none') {
      getData();
    }
  }, [isAuthenticated, inProgress]);

  return (
    <>
      <Helmet title="Profile" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">My Profile</h1>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  Profile
                </Card.Title>
              </Card.Header>
              <Card.Body>{data && <DisplayProfileData profileData={data} />}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
