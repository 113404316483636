import { addMethod, string, object } from 'yup';

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
const forbiddenDomains = [
  'apple.com',
  'me.com',
  'icloud.com',
  'mac.com',
  'xtra.com',
  'microsoft.com',
  'google.com',
  'yahoo.com',
  'blogspot.com',
  'gmail.com',
  'telecom.co.nz',
  'gen-i.co.nz',
  'nzherald.co.nz',
  'stuff.co.nz',
  'aa.co.nz',
  'bbc.co.nz',
  'xtra.co.nz',
  'hotmail.com',
  'orcon.net.nz',
  'slingshot.co.nz',
  'vodafone.co.nz',
  'xnet.co.nz',
  'msn.com',
  'clear.net.nz',
  'telecombusinesshub.co.nz',
  'amazon.com',
  'facebook.com',
  'twitter.com',
  'github.com',
];

addMethod(string, 'checkDomain', function checkDomainWrapper(errorMessage) {
  return this.test('check-domain', errorMessage, function checkDomain(email) {
    const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(email);
    if (match) {
      if (forbiddenDomains.indexOf(match[1].toLowerCase()) >= 0) {
        return this.createError({ errorMessage });
      }
      return email;
    }
    return true;
  });
});

const validationSchema = object().shape({
  firstName: string().required('First name required'),
  lastName: string().required('Last name required'),
  emailAddress: string()
    .email('Enter a valid email address')
    .checkDomain('Email domain should be a company domain')
    .required('Please! Enter your email adress'),
  mobileNumber: string().required('Mobile number required'),
  role: string().required('Role is required'),
  companyName: string().required('Company name required'),
});

const validateDomainInput = (input) => {
  if (!input || input === 'undefined') {
    return { valid: false };
  }
  if (!domainRegex.test(input) && !emailRegex.test(input)) {
    return { valid: false, domainInputError: 'Enter a valid Email or Domain' };
  }
  const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(input);

  if (match && forbiddenDomains.indexOf(match[1].toLowerCase()) >= 0) {
    return { valid: false, domainInputError: 'Email domain should be a company domain' };
  }
  if (forbiddenDomains.indexOf(input) >= 0) {
    return { valid: false, domainInputError: 'Domain should be a company domain' };
  }
  if (!domainRegex.test(input) && !emailRegex.test(input)) {
    return { valid: false, domainInputError: 'Enter a valid Email or Domain' };
  }
  return { valid: domainRegex.test(input) || emailRegex.test(input) };
};

const getDomain = (input) => {
  const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(input);
  if (match) {
    return match[1].toLowerCase();
  }
  return input;
};

export { validateDomainInput, validationSchema, getDomain };
