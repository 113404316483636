import { Outlet } from 'react-router-dom';

import Main from './components/main';

const LandingLayout = ({ children }) => {
  return (
    <Main>
      {children}
      <Outlet />
    </Main>
  );
};

export default LandingLayout;
