/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import get from 'lodash.get';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';
import MultiDomainInput from '../components/multi_domain_input';
import { validationSchema } from '../lib/form_helper';

import dratRequestCreateMutation from '../mutations/drat_request_create_mutation';
import { toastSuccess, toastError } from '../lib/toast';
import useSettings from '../hooks/use_settings';

const DratRequestDetailCreate = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized] = useState(true);
  const { isMutating, setIsMutating } = useSettings();
  const navigate = useNavigate();
  const initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    mobileNumber: '',
    role: '',
    companyName: '',
  };
  const [domains, setDomains] = useState([]);
  const [domainsError, setDomainsError] = useState([]);

  const handleSetDomains = (domainItems) => {
    setDomains(domainItems);
  };

  const handleSetDomainsError = (error) => {
    setDomainsError(error);
  };

  const [dratRequestCreate, { loading: createLoading, error: createError }] = useMutation(
    dratRequestCreateMutation
  );

  const handleDratRequestCreate = async ({
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    role,
    companyName,
  }) => {
    const mutationOptions = {
      variables: {
        input: {
          firstName,
          lastName,
          emailAddress,
          mobileNumber,
          role,
          companyName,
          domains,
        },
      },
    };
    console.log(mutationOptions);
    try {
      setIsMutating(true);
      const mutationResponse = await dratRequestCreate(mutationOptions);
      toastSuccess('Drat Request Creation ok');
      const dratRequestId = get(mutationResponse, 'data.dratRequestCreate.id');
      navigate(`/search/drat-request/${dratRequestId}`);
    } catch (err) {
      console.log(err.toString());
      toastError('Drat Request Creation failed');
    } finally {
      setIsMutating(false);
    }
  };

  const renderContent = () => (
    <div className="row">
      <h1 className="mt-3 mb-4">New Drat Request</h1>
      <div>
        <div className="input-group mb-3 col-xs-2">
          <div className="card card-container p-4 w-75">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleDratRequestCreate}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="firstName">
                      First name:
                    </label>
                    <div className="col-sm-9">
                      <Field
                        className="form-control"
                        type="text"
                        name="firstName"
                        id="firstName"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="p-1 m-0 alert alert-warning error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="lastName">
                      Last name:
                    </label>
                    <div className="col-sm-9">
                      <Field
                        className="form-control"
                        type="text"
                        name="lastName"
                        id="lastName"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="p-1 m-0 alert alert-warning error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="emailAddress">
                      Email address:
                    </label>
                    <div className="col-sm-9">
                      <Field
                        className="form-control"
                        type="text"
                        name="emailAddress"
                        id="emailAddress"
                      />
                      <ErrorMessage
                        name="emailAddress"
                        component="div"
                        className="p-1 m-0 alert alert-warning error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="mobileNumber">
                      Mobile number:
                    </label>
                    <div className="col-sm-9">
                      <Field
                        className="form-control"
                        type="tel"
                        name="mobileNumber"
                        id="mobileNumber"
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="div"
                        className="p-1 m-0 alert alert-warning error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="role">
                      Role:
                    </label>
                    <div className="col-sm-9">
                      <Field className="form-control" type="text" name="role" id="role" />
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="p-1 m-0 alert alert-warning error-message"
                      />
                    </div>
                  </div>

                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="companyName">
                      Company Name:
                    </label>
                    <div className="col-sm-9">
                      <Field
                        className="form-control"
                        type="text"
                        name="companyName"
                        id="companyName"
                      />
                      <ErrorMessage
                        name="companyName"
                        component="div"
                        className="p-1 m-0 alert alert-warning error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group row my-4">
                    <label className="col-sm-3 col-form-label" htmlFor="domains">
                      Domains:
                    </label>
                    <div className="col-sm-9">
                      <MultiDomainInput
                        handleSetDomains={handleSetDomains}
                        handleSetDomainsError={handleSetDomainsError}
                      />
                    </div>
                  </div>
                  <div className="form-group row my-4 p-3">
                    <div className="col-sm-6 col-form-label" />
                    <button
                      type="submit"
                      className="btn btn-primary btn-block col-sm-4"
                      disabled={
                        isSubmitting ||
                        !!(errors.firstName && touched.firstName) ||
                        !!(errors.lastName && touched.lastName) ||
                        !!(errors.emailAddress && touched.emailAddress) ||
                        !!(errors.mobileNumber && touched.mobileNumber) ||
                        !!(errors.role && touched.role) ||
                        !!(errors.companyName && touched.companyName) ||
                        domainsError
                      }
                    >
                      {(createLoading || isSubmitting) && (
                        <span className="spinner-border spinner-border-sm" />
                      )}
                      <span>Create</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(isMutating)}
      {renderError(createError)}
      {isAuthenticated && isAuthorized && !createError && renderContent()}
    </div>
  );
};

export default DratRequestDetailCreate;
