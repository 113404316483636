import { useState, useEffect, useCallback } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

import { validateDomainInput, getDomain } from '../lib/form_helper';

const getLabel = (email, index, removeEmail) => {
  return (
    <div data-tag key={index}>
      {email}
      <span data-tag-handle aria-hidden="true" onClick={() => removeEmail(index)}>
        ×
      </span>
    </div>
  );
};

const MultiDomainInput = ({ handleSetDomains, handleSetDomainsError }) => {
  const [domainInputs, setDomainInputs] = useState([]);
  const [domains, setDomains] = useState([]);
  const [domainError, setDomainError] = useState('');
  const handleDomains = useCallback(
    (items) => {
      handleSetDomains(items);
    },
    [handleSetDomains]
  );

  const handleError = useCallback(
    (error) => {
      handleSetDomainsError(error);
    },
    [handleSetDomainsError]
  );

  useEffect(() => {
    const domainItems = domainInputs.map((input) => {
      return getDomain(input);
    });
    setDomains(domainItems);
  }, [domainInputs]);

  useEffect(() => {
    handleDomains(domains);
  }, [handleDomains, domains]);

  useEffect(() => {
    handleError(domainError);
  }, [handleError, domainError]);

  const handleDomainChange = (domainsList) => {
    setDomainInputs(domainsList);
  };

  const handleValidateEmail = (input) => {
    if (!validateDomainInput(input).valid) {
      setDomainError(validateDomainInput(input).domainInputError);
      return false;
    }
    setDomainError('');
    return validateDomainInput(input).valid;
  };

  return (
    <>
      <ReactMultiEmail
        placeholder="Input your domains or emails"
        name="domains"
        id="domains"
        emails={domainInputs}
        onChange={handleDomainChange}
        getLabel={getLabel}
        validateEmail={handleValidateEmail}
      />
      {domainError && (
        <div className="p-1 m-0 alert alert-warning error-message">{domainError}</div>
      )}
    </>
  );
};

export default MultiDomainInput;
