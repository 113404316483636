import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';

import dratRequestShowQuery from '../queries/page_drat_request_show_query';

const DratRequestShow = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState(null);
  const [dratRequest, setDratRequest] = useState({});
  const [dratRequestId, setDratRequestId] = useState(null);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(dratRequestShowQuery, {
    variables: { id: dratRequestId },
    skip: !dratRequestId,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setDratRequestId(params.id);
    if (!params.id) {
      setDratRequest({});
    }
  }, [params.id]);

  useEffect(() => {
    setDratRequest(pageData?.dratRequest);
  }, [pageData]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleFetch = () => {
    navigate(`/search/drat-request/${inputValue}`);
  };

  const handleRefetch = () => pageRefetch();

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const renderContent = () => (
    <>
      <Helmet title="Drat Request show" />
      <h1>Drat request show page</h1>

      <Container fluid className="p-0">
        <Row>
          <Col sm={12}>
            <div className="float-none">
              <div className="float-end my-2">
                <Button variant="primary" onClick={handleRefetch} disabled={pageLoading}>
                  Refresh
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <div className="input-group mb-3 col-xs-2">
            <input
              type="text"
              className="form-control"
              placeholder="enter an id"
              aria-label="domain"
              aria-describedby="basic-addon2"
              onChange={handleChange}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleFetch}
                disabled={!inputValue || pageLoading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {dratRequest ? (
          <Row>
            <Col>
              <pre className="h6">{JSON.stringify(dratRequest, undefined, 2)}</pre>
            </Col>
          </Row>
        ) : (
          dratRequestId && <p>No Information provided!</p>
        )}
      </Container>
    </>
  );
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default DratRequestShow;
