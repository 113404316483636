/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';
import get from 'lodash.get';

import {
  Card,
  Col,
  Container,
  Row,
  Button,
  ButtonGroup,
  Table,
  Pagination,
  Form,
  ListGroup,
  Tab,
  Nav,
} from 'react-bootstrap';

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { useQuery, NetworkStatus } from '@apollo/client';

import {
  renderUnauthenticated,
  renderOverlay,
  renderError,
} from '../components/render_helpers';
import ReactTableFilter from '../components/react_table_filter';
import tenantQuery from '../queries/tenant_query';

const TenantShow = () => {
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();
  const navigate = useNavigate();

  const [isAuthorized] = useState(true);
  const [tenant, setTenant] = useState(null);
  const [tenantUsersList, setTenantUsersList] = useState([]);
  const [tenantGroupsList, setTenantGroupsList] = useState([]);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false }]);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(tenantQuery, {
    variables: { id: params.id },
    skip: !params.id,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (pageData?.tenant) {
      setTenant(pageData.tenant);
      setTenantUsersList(pageData.tenant.users);
      setTenantGroupsList(pageData.tenant.groups);
    }
  }, [pageData]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleUserShow = useCallback(
    (e) => {
      const { id } = params;
      const userId = e.currentTarget.getAttribute('data-user-id');
      navigate(`/tenants/${id}/users/${userId}`);
    },
    [navigate, params]
  );

  const filterWithUndefined = (row, column, filter) => {
    if (filter.length < 2) {
      return false;
    }
    const value = get(row, ['original', ...column.split('_')]);
    return !!(value && value.toLowerCase().includes(filter.toLowerCase()));
  };

  const renderActionCellUser = useCallback(
    (cell) => {
      const { tenantId, adUserId } = cell.getValue();
      return (
        <ButtonGroup key={tenantId}>
          <Button
            className="me-1"
            size="sm"
            data-id={tenantId}
            data-user-id={adUserId}
            onClick={handleUserShow}
          >
            show
          </Button>
        </ButtonGroup>
      );
    },
    [handleUserShow]
  );

  const usersColumns = useMemo(
    () => [
      {
        Header: 'Id',
        id: 'id',
        accessorKey: 'id',
        filterFn: filterWithUndefined,
      },
      {
        Header: 'Name',
        id: 'displayName',
        accessorKey: 'displayName',
      },
      createColumnHelper().accessor((user) => user, {
        id: 'Actions',
        header: () => 'Actions',
        cell: renderActionCellUser,
        enableColumnFilter: false,
      }),
    ],
    [renderActionCellUser]
  );

  const reactTableUser = useReactTable({
    columns: usersColumns,
    data: tenantUsersList,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize: 100 } },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  const handleGroupShow = useCallback(
    (e) => {
      const { id } = params;
      const groupId = e.currentTarget.getAttribute('data-group-id');
      navigate(`/tenants/${id}/groups/${groupId}`);
    },
    [navigate, params]
  );

  const renderActionCellGroup = useCallback(
    (cell) => {
      const { tenantId, adGroupId } = cell.getValue();
      return (
        <ButtonGroup key={tenantId}>
          <Button
            className="me-1"
            size="sm"
            data-id={tenantId}
            data-group-id={adGroupId}
            onClick={handleGroupShow}
          >
            show
          </Button>
        </ButtonGroup>
      );
    },
    [handleGroupShow]
  );

  const groupsColumns = useMemo(
    () => [
      {
        Header: 'Id',
        id: 'id',
        accessorKey: 'id',
        filterFn: filterWithUndefined,
      },
      {
        Header: 'Name',
        id: 'displayName',
        accessorKey: 'displayName',
      },
      createColumnHelper().accessor((group) => group, {
        id: 'Actions',
        header: () => 'Actions',
        cell: renderActionCellGroup,
        enableColumnFilter: false,
      }),
    ],
    [renderActionCellGroup]
  );

  const reactTableGroup = useReactTable({
    columns: groupsColumns,
    data: tenantGroupsList,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize: 100 } },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  const renderContent = () => {
    const { id, name, users, groups } = tenant;
    return (
      <>
        <p className="h2 fw-normal mt-3 mb-4">Tenant</p>
        <Helmet title="Tenant Item" />
        <Container fluid className="p-0">
          {id ? (
            <>
              <Row>
                <Col sm={12}>
                  <div className="float-none">
                    {/* <div className="float-start">
                      <h1 className="h3 mb-3">{`Tenant list for ${get(
                        account,
                        'name',
                        'user'
                      )}`}</h1>
                    </div> */}
                    <div className="float-end my-2">
                      <Button
                        variant="primary"
                        onClick={handleRefetch}
                        disabled={pageLoading}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="basic">Tenant</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="users">Users</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="groups">Groups</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="basic">
                          <ListGroup variant="flush">
                            <ListGroup.Item variant="info">
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                Tenant info:
                              </span>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                ID:
                              </span>
                              {id}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '100px' }}>
                                Name:
                              </span>
                              {name}
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="users">
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              {id && users?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <p className="h2 fw-normal mt-3 mb-4">Tenant Users</p>
                                    <Card>
                                      <Card.Body>
                                        <Table size="sm" responsive striped bordered>
                                          <thead>
                                            {reactTableUser
                                              .getHeaderGroups()
                                              .map((headerGroup) => (
                                                <tr key={headerGroup.id}>
                                                  {headerGroup.headers.map((header) => (
                                                    <th
                                                      key={header.id}
                                                      colSpan={header.colSpan}
                                                    >
                                                      {header.isPlaceholder ? null : (
                                                        <>
                                                          <div
                                                            {...{
                                                              className:
                                                                header.column.getCanSort()
                                                                  ? 'cursor-pointer select-none'
                                                                  : '',
                                                              onClick:
                                                                header.column.getToggleSortingHandler(),
                                                            }}
                                                          >
                                                            {flexRender(
                                                              header.column.columnDef
                                                                .header,
                                                              header.getContext()
                                                            )}
                                                            {{
                                                              asc: ' 🔼',
                                                              desc: ' 🔽',
                                                            }[
                                                              header.column.getIsSorted()
                                                            ] ?? null}
                                                          </div>
                                                          {header.column.getCanFilter() && (
                                                            <div>
                                                              <ReactTableFilter
                                                                column={header.column}
                                                                reactTable={
                                                                  reactTableUser
                                                                }
                                                              />
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    </th>
                                                  ))}
                                                </tr>
                                              ))}
                                          </thead>
                                          <tbody>
                                            {reactTableUser
                                              .getRowModel()
                                              .rows.map((row) => (
                                                <tr key={row.id}>
                                                  {row.getVisibleCells().map((cell) => (
                                                    <td key={cell.id}>
                                                      {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                      )}
                                                    </td>
                                                  ))}
                                                </tr>
                                              ))}
                                          </tbody>
                                        </Table>
                                        <Row>
                                          <Col md="6">
                                            <span className="mx-2">
                                              Page{' '}
                                              <strong>
                                                {reactTableUser.getState().pagination
                                                  .pageIndex + 1}{' '}
                                                of {reactTableUser.getPageCount()}
                                              </strong>
                                            </span>
                                            <span className="ms-3 me-2">Show:</span>
                                            <Form.Select
                                              className="d-inline-block w-auto"
                                              value={
                                                reactTableUser.getState().pagination
                                                  .pageSize
                                              }
                                              onChange={(e) => {
                                                reactTableUser.setPageSize(
                                                  Number(e.target.value)
                                                );
                                              }}
                                            >
                                              {[10, 25, 50, 100].map((size) => (
                                                <option key={size} value={size}>
                                                  {size}
                                                </option>
                                              ))}
                                            </Form.Select>
                                            <span className="ms-3 me-2">Go to page:</span>
                                            <Form.Control
                                              className="d-inline-block"
                                              type="number"
                                              defaultValue={
                                                reactTableUser.getState().pagination
                                                  .pageIndex + 1
                                              }
                                              onChange={(e) => {
                                                const pageNumber = e.target.value
                                                  ? Number(e.target.value) - 1
                                                  : 0;
                                                reactTableUser.setPageIndex(pageNumber);
                                              }}
                                              style={{ width: '75px' }}
                                            />
                                          </Col>
                                          <Col md="6">
                                            <Pagination className="float-end">
                                              <Pagination.First
                                                onClick={() =>
                                                  reactTableUser.setPageIndex(0)
                                                }
                                                disabled={
                                                  !reactTableUser.getCanPreviousPage()
                                                }
                                              />
                                              <Pagination.Prev
                                                onClick={() =>
                                                  reactTableUser.previousPage()
                                                }
                                                disabled={
                                                  !reactTableUser.getCanPreviousPage()
                                                }
                                              />
                                              <Pagination.Next
                                                onClick={() => reactTableUser.nextPage()}
                                                disabled={
                                                  !reactTableUser.getCanNextPage()
                                                }
                                              />
                                              <Pagination.Last
                                                onClick={() =>
                                                  reactTableUser.setPageIndex(
                                                    reactTableUser.getPageCount() - 1
                                                  )
                                                }
                                                disabled={
                                                  !reactTableUser.getCanNextPage()
                                                }
                                              />
                                            </Pagination>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col>
                                    <Card>
                                      <Card.Header className="pb-0">
                                        <Card.Title tag="h5" className="mb-0">
                                          Not found
                                        </Card.Title>
                                      </Card.Header>
                                      <Card.Body>
                                        We were unable to retrieve the tenant users table
                                        <ul>
                                          <li>It may have expired</li>
                                          <li>
                                            This tenant id may not relate to your id
                                          </li>
                                        </ul>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="groups">
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              {id && groups?.length > 0 ? (
                                <Row>
                                  <Col>
                                    <p className="h2 fw-normal mt-3 mb-4">
                                      Tenant Groups
                                    </p>
                                    <Card>
                                      <Card.Body>
                                        <Table size="sm" responsive striped bordered>
                                          <thead>
                                            {reactTableGroup
                                              .getHeaderGroups()
                                              .map((headerGroup) => (
                                                <tr key={headerGroup.id}>
                                                  {headerGroup.headers.map((header) => (
                                                    <th
                                                      key={header.id}
                                                      colSpan={header.colSpan}
                                                    >
                                                      {header.isPlaceholder ? null : (
                                                        <>
                                                          <div
                                                            {...{
                                                              className:
                                                                header.column.getCanSort()
                                                                  ? 'cursor-pointer select-none'
                                                                  : '',
                                                              onClick:
                                                                header.column.getToggleSortingHandler(),
                                                            }}
                                                          >
                                                            {flexRender(
                                                              header.column.columnDef
                                                                .header,
                                                              header.getContext()
                                                            )}
                                                            {{
                                                              asc: ' 🔼',
                                                              desc: ' 🔽',
                                                            }[
                                                              header.column.getIsSorted()
                                                            ] ?? null}
                                                          </div>
                                                          {header.column.getCanFilter() && (
                                                            <div>
                                                              <ReactTableFilter
                                                                column={header.column}
                                                                reactTable={
                                                                  reactTableGroup
                                                                }
                                                              />
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    </th>
                                                  ))}
                                                </tr>
                                              ))}
                                          </thead>
                                          <tbody>
                                            {reactTableGroup
                                              .getRowModel()
                                              .rows.map((row) => (
                                                <tr key={row.id}>
                                                  {row.getVisibleCells().map((cell) => (
                                                    <td key={cell.id}>
                                                      {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                      )}
                                                    </td>
                                                  ))}
                                                </tr>
                                              ))}
                                          </tbody>
                                        </Table>
                                        <Row>
                                          <Col md="6">
                                            <span className="mx-2">
                                              Page{' '}
                                              <strong>
                                                {reactTableGroup.getState().pagination
                                                  .pageIndex + 1}{' '}
                                                of {reactTableGroup.getPageCount()}
                                              </strong>
                                            </span>
                                            <span className="ms-3 me-2">Show:</span>
                                            <Form.Select
                                              className="d-inline-block w-auto"
                                              value={
                                                reactTableGroup.getState().pagination
                                                  .pageSize
                                              }
                                              onChange={(e) => {
                                                reactTableGroup.setPageSize(
                                                  Number(e.target.value)
                                                );
                                              }}
                                            >
                                              {[10, 25, 50, 100].map((size) => (
                                                <option key={size} value={size}>
                                                  {size}
                                                </option>
                                              ))}
                                            </Form.Select>
                                            <span className="ms-3 me-2">Go to page:</span>
                                            <Form.Control
                                              className="d-inline-block"
                                              type="number"
                                              defaultValue={
                                                reactTableGroup.getState().pagination
                                                  .pageIndex + 1
                                              }
                                              onChange={(e) => {
                                                const pageNumber = e.target.value
                                                  ? Number(e.target.value) - 1
                                                  : 0;
                                                reactTableGroup.setPageIndex(pageNumber);
                                              }}
                                              style={{ width: '75px' }}
                                            />
                                          </Col>
                                          <Col md="6">
                                            <Pagination className="float-end">
                                              <Pagination.First
                                                onClick={() =>
                                                  reactTableGroup.setPageIndex(0)
                                                }
                                                disabled={
                                                  !reactTableGroup.getCanPreviousPage()
                                                }
                                              />
                                              <Pagination.Prev
                                                onClick={() =>
                                                  reactTableGroup.previousPage()
                                                }
                                                disabled={
                                                  !reactTableGroup.getCanPreviousPage()
                                                }
                                              />
                                              <Pagination.Next
                                                onClick={() => reactTableGroup.nextPage()}
                                                disabled={
                                                  !reactTableGroup.getCanNextPage()
                                                }
                                              />
                                              <Pagination.Last
                                                onClick={() =>
                                                  reactTableGroup.setPageIndex(
                                                    reactTableGroup.getPageCount() - 1
                                                  )
                                                }
                                                disabled={
                                                  !reactTableGroup.getCanNextPage()
                                                }
                                              />
                                            </Pagination>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col>
                                    <Card>
                                      <Card.Header className="pb-0">
                                        <Card.Title tag="h5" className="mb-0">
                                          Not found
                                        </Card.Title>
                                      </Card.Header>
                                      <Card.Body>
                                        We were unable to retrieve the tenant groups table
                                        <ul>
                                          <li>It may have expired</li>
                                          <li>
                                            This tenant id may not relate to your id
                                          </li>
                                        </ul>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the tenant for review
                    <ul>
                      <li>It may have expired</li>
                      <li>This tenant id may not relate to your id</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  };
  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default TenantShow;
